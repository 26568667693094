.notification {
  max-width: none;

  .toast-header {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .toast-body {
    margin: -1px;
    padding: 0;

    .notification-alert {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      margin-bottom: 0;
      margin-right: 1px;
      margin-left: 1px;
    }
  }
}