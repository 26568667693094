.Footer {
  position: relative;
  background-color: #142a93;
  margin-left: -15px;
  margin-right: -15px;
  //margin-bottom: 48px;

  .Footer-footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: white;
    padding: 23px;

    .Footer-logoWrapper {
      .Footer-logo {
        max-height: 100px;
      }
    }

    .Footer-links {
      display: flex;
      justify-content: center;
      align-content: center;

      & > div::before {
        content: " • ";
      }

      & > div:first-of-type::before {
        content: "";
      }

      & > div::after {
        padding-left: 5px;
        content: " ";
      }

      a {
        color: white;
        -webkit-font-smoothing: antialiased;
      }
    }
  }

  .Footer-copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #031a77;
    color: white;
    height: 48px;

    a {
      color: white;
    }
  }

}

@media only screen and (max-width: 480px) {
  .Footer {
    margin-bottom: 80px;
  }
}