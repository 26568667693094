@import "./../../styles/colors";

.Checkbox {
  .Checkbox-label {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;

    .Checkbox-container {
      display: inline-block;
      vertical-align: middle;
      height: 16px;

      .Checkbox-input {
        border: 0;
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        white-space: nowrap;
        width: 1px;

        &:focus ~ .Checkbox-styled {
          box-shadow: 0 0 0 3px pink;
        }
      }

      .Checkbox-styled {
        display: inline-block;
        width: 16px;
        height: 16px;
        background: papayawhip;
        border-radius: 3px;
        transition: all 150ms;
        margin-right: 10px;
        cursor: pointer;

        .Checkbox-input:focus ~ {
          box-shadow: 0 0 0 3px pink;
        }

        .Checkbox-icon {
          vertical-align: top;
          fill: none;
          stroke: white;
          stroke-width: 2px;
          visibility: hidden;
        }


        &--checked {
          background-color: salmon;

          .Checkbox-icon {
            visibility: visible;
          }
        }

        &--disabled {
          border: 1px solid $colorDisabledBg;
          background-color: $colorDisabledBg;
        }
      }
    }
  }

  &--disabled {
    .Checkbox-label {
      color: $colorDisabledText;
    }
  }
}
