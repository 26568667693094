@import "./../../styles/colors";

.DropdownTree {
  position: relative;
  //max-width: 103px!important;
  .DropdownTree-trigger {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    li {
      width: 100% !important;
    }

    &--error {
      border-color: #dc3545;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }

  .DropdownTree-content {
    position: absolute;
    z-index: 5999;
    //min-width: 350px;
    min-width: 200px;
    //max-width: 350px;
    max-height: calc(40px * 8);
    overflow-y: auto;
    left: 15px;
    top: 37px;
    right: 15px;
    border-left: 1px solid #ced4da;
    border-right: 1px solid #ced4da;
    border-radius: 0.25rem;

    .DropdownTree-menuItem {
      display: flex;
      align-items: center;
      height: 2.5rem;
      color: $colorText;
      background-color: #f4f4f4;
      padding: 4px;
      padding-left: 0;
      border-top: 1px solid #ced4da;
      border-bottom: 1px solid transparent;
      font-size: 14px;
      font-weight: 400;

      &:hover {
        background-color: #e5e5e5;
      }

      &:last-child {
        border-bottom-color: #ced4da;
      }

      &--search {
        padding: 0 !important;
        border: none;
        margin-top: -1px;
        margin-left: -1px;
        margin-right: -1px;
        margin-bottom: -2px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .DropdownTree-search {
        width: 100%;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        padding: 0 0.75rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .DropdownTree-menuItemLabel {
        line-height: 16px;
      }

      .DropdownTree-menuItemIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 4px;
        width: 20px;
        min-width: 16px;
        height: 20px;
        font-size: 18px;
        cursor: pointer;
      }

      .DropdownTree-menuItemRadio, .bx--checkbox-wrapper {
        max-width: calc(100% - 18px);

        .bx--radio-button:disabled + .bx--radio-button__label, .bx--checkbox-label-text {
          color: $colorText
        }

        .bx--radio-button__label {
          span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .bx--checkbox-label-text {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &--partial {
          .bx--radio-button__appearance {
            border-color: var(--cds-interactive-04);
          }
        }

        &--checked {
          .bx--radio-button__appearance {
            display: flex;
            align-items: center;
            justify-content: center;
            border-color: var(--cds-interactive-04);

            &:before {
              background-color: var(--cds-interactive-04);
              content: '';
              display: inline-block;
              position: relative;
              width: 0.5rem;
              height: 0.5rem;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }

  &--disabled {
    color: red;

    .DropdownTree-trigger {
      .tag-item {
        background-color: var(--cds-field-01) !important;
        border-bottom: none !important;
        cursor: not-allowed !important;

        .DropdownTree-text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        input {
          outline: 2px solid transparent;
          outline-offset: -2px;
          cursor: not-allowed;
          //background-color: #f4f4f4;
          background-color: var(--cds-disabled-01);
          border-bottom: 1px solid transparent;
          color: var(--cds-disabled-02);
          //cursor: not-allowed !important;
          &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: var(--cds-disabled-02);
            opacity: 1; /* Firefox */
          }

          &:focus {
            outline: 2px solid transparent !important;
            outline-offset: -2px !important;
          }

          &:active {
            outline: 2px solid transparent !important;
            outline-offset: -2px !important;
          }
        }
      }

      &::after {
        cursor: not-allowed !important;
        color: var(--cds-disabled-02) !important;
        border-color: var(--cds-disabled-02) !important;
      }
    }
  }
}
