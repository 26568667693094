@import "../../styles/colors";

.TopMenu {
  .TopMenu-logo {
    display: flex;
    justify-content: space-between;

    .TopMenu-hamburger {
      padding: 20px;
      color: #011e40;
      font-size: 1.3rem;
    }

    > a {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .menu {
    transition: wid 1500ms ease-out;
  }

  .TopMenu-topList {
    display: flex;
  }
}