
.ToastComponent {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9001;
  min-width: 320px;
  max-width: 420px;

  .ToastComponent-toast {
    width: auto;
    // backgroundColor: '#FFF',
  }
}
