$colorWhite: #FFFFFF;

$colorBg: #f5f5f5;
$colorBg05: #161616;

$colorBgHover05: #353535;

$colorMain: #e4e4e4;
//$colorSelected: #c9c9c9;
//$colorMain2: #888888;
$colorMain2: #939597;
$colorText: #161616;
$colorText02: #525252;
$colorDisabledBg: #c6c6c6;
$colorDisabledText: #6f7878;

$colorInteractive02: #393939;


$colorLink: #007bff;
$colorSelected: #0f62fe;

$colorDigitizer: #0D155B;
$colorDigitizer1: #5A84F9;
$colorDigitizer2: #00ADEF;
$colorDigitizer3: #F0297C;
$colorDigitizer4: #F7D64D;
$colorYellow: #F7D64D;

$colorDanger: #dc3545;