@import "../../styles/colors";

.Button {
  margin-right: 1rem;

  &.btn {
    border-radius: 0;
    padding: 0.6875rem 1rem;
    //padding-bottom: 0.6875rem;
  }

  &--primary {
    color: $colorDigitizer;
    background-color: $colorDigitizer4;
    border-color: $colorDigitizer4;

    &:hover {
      color: $colorDigitizer;
      background-color: darken($colorDigitizer4, 20%);
      border-color: darken($colorDigitizer4, 20%);
    }
  }

  &--secondary {
    color: $colorDigitizer;
    background-color: $colorDigitizer2;
    border-color: $colorDigitizer2;

    &:hover {
      color: $colorDigitizer;
      background-color: darken($colorDigitizer2, 10%);
      border-color: darken($colorDigitizer2, 10%);
    }
  }

  &--danger {
    color: $colorDigitizer;
    background-color: $colorDanger;
    border-color: $colorDanger;

    &:hover {
      color: $colorDigitizer;
      background-color: darken($colorDanger, 10%);
      border-color: darken($colorDanger, 10%);
    }
  }

  &--disabled {
    color: $colorDisabledText !important;
    background-color: $colorDisabledBg !important;
    border-color: $colorDisabledBg !important;
    cursor: not-allowed;
  }

  &:first-child {
    margin-left: 1rem;
  }

  .Button-icon {
    margin-right: 1rem;
  }
}