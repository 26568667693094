.FeedbackModal {
  .FeedbackModal-floatingBar {
    position: fixed;
    z-index: 9999;
    right: 0;
    bottom: 220px;
    transform: rotate3d(0, 0, 1, -90deg);
    transform-origin: 100% 100%;
  }

  .FeedbackModal-feedbackButton {
    background-color: #8C9BA5;
    padding: 0 20px;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.125rem;
    -webkit-transform-origin: 50% 51%;
  }
}

.Feedback {
  .bx--modal-content {
    border-top: 1px solid var(--cds-ui-03);
    padding-right: 1rem;
    //min-height: 310px;
  }
}
