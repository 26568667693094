.mainMenu {

  &--mobile {
    width: 100vw;

    .mainMenu-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      a {
        padding: 10px 0;
      }
    }
  }
}