.registerSuccess {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 194px - 48px);

  .registerSuccess-pageContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
      font-weight: bold;
    }

    h2 {
      font-weight: normal;
      text-align: center;
      font-size: 25px;
    }

    .registerSuccess-icon {
      display: block;
      font-size: 80px;
      margin-bottom: 20px;
      color: green;
    }
  }
}
