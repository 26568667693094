.DropdownForm {
  .DropdownForm-customDropdown {
    display: flex;
    justify-content: space-between;
    color: #495057;
    text-decoration: none;
  }
  .DropdownForm-invalidFeedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
    display: block;
  }
}