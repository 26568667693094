.App-Container {
  padding-left: 0;
  padding-right: 0;
}

.main {
  order: 1;
  //padding: 2rem 4rem;
  min-height: calc(100vh - (152px + 194px));

/*  &--topArea {
    min-height: calc(100vh - (48px + 48px + 194px));
  }*/
}

/*
.Container {
  margin-top: 48px;
  max-height: calc(100vh - 48px);
  overflow: hidden;

  &--scroll {
    overflow-y: scroll;
  }

  .Container-wrapper--minHeight {
    min-height: calc(100vh - 48px - 194px);
  }
}
*/

/*
@media only screen and (max-width: 480px) {
  .main {
    padding-left: 0;
    padding-right: 0;
  }

  .Container {
    margin-bottom: 80px;
    max-height: calc(100vh - 96px);
    padding-left: 0;
    padding-right: 0;
    padding-bottom: env(safe-area-inset-bottom);
  }
}*/
