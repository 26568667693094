@import "./../../styles/colors";

.Radio {
  .Radio-label {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;

    .Radio-container {
      display: inline-block;
      vertical-align: middle;
      height: 22px;

      .Radio-input {
        border: 0;
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        white-space: nowrap;
        width: 1px;

        &:focus ~ .Radio-styled {
          box-shadow: 0 0 0 3px pink;
        }
      }

      .Radio-styled {
        display: inline-block;
        width: 22px;
        height: 22px;
        background: transparent;
        border: 1px solid #161616;
        border-radius: 50%;
        transition: all 150ms;
        margin-right: 10px;
        cursor: pointer;

        .Radio-styledInner {
          display: inline-block;
          width: 10px;
          height: 10px;
          margin-left: 5px;
          margin-top: 5px;
          //background: papayawhip;
          background: transparent;
          border-radius: 50%;
          transition: all 150ms;
          margin-right: 10px;
        }

        .Radio-input:focus ~ {
          box-shadow: 0 0 0 3px pink;
        }

        .Radio-icon {
          vertical-align: top;
          fill: none;
          stroke: white;
          stroke-width: 2px;
          visibility: hidden;
        }

        &--checked {
            border-color: salmon;
          .Radio-styledInner {
            //background-color: #161616;
            background-color: salmon;
          }

          .Radio-icon {
            visibility: visible;
          }
        }

        &--disabled {
          cursor: not-allowed;
          border: 1px solid $colorDisabledText;
          background-color: $colorDisabledBg;

          .Radio-styledInner {
            //background-color: #161616;
            background-color: $colorDisabledText;
          }
        }
      }
    }
  }

  &--disabled {
    .Radio-label {
      color: $colorDisabledText;
    }
  }
}
