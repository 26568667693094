@import "../../styles/colors";

.Icon {
  position: relative;
  display: inline-block;
  //border-bottom: 1px dotted $colorMain2; /* If you want dots under the hoverable text */

  /* Tooltip text */
  .Icon-tooltip {
    visibility: hidden;
    width: 120px;
    background-color: $colorMain2;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif, serif;
    font-size: .825rem;
    font-weight: 400;
    line-height: 1.625;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  &:hover .Icon-tooltip {
    visibility: visible;
  }

}
